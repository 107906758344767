@import './styles/theme.scss';

body {
  background-color: $dark-color;
  color: $light-color;
}

#app-container {
  width: 100%;
  overflow: hidden;
}

#scene-container {
  &,
  canvas {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}
