@import '../styles/theme.scss';

.devblog-container {
    position: relative;
    width: 100%;
    max-width: 1280px;
    padding-top: $container-padding-y;
    padding-bottom: $container-padding-y;
    padding-left: $container-padding-x;
    padding-right: $container-padding-x;
    margin: 0 auto;
    color: $light-color;
    box-sizing: border-box;

    &--error,
    &--loading {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100vh;
    }

    hr {
        border: none;
        border-bottom: 1px solid $medium-color;
        margin-bottom: 4rem;
    }

    figure {
        margin: 4rem auto;
        max-width: 100%;

        img {
            margin: auto;
            display: block;
            position: relative;
            max-width: 100%;
            height: auto;
        }

        figcaption {
            text-align: center;
            font-size: 0.8rem;
            margin-top: 1rem;
        }
    }

    pre {
        padding: 1rem;
        background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
        background-blend-mode: overlay;
        white-space: pre-wrap;
        overflow-x: scroll;
    }
}