@import '../styles/theme.scss';

#resume {
    $stripe-height: 3rem;
    position: relative;
    
    [data-scroll-section] {
        position: relative;
    }
    
    .resume-container {
        position: relative;
        max-width: 1280px;
        padding-top: $container-padding-y;
        padding-bottom: $container-padding-y;
        padding-left: $container-padding-x;
        padding-right: $container-padding-x;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $light-color;
        box-sizing: border-box;
        
        &__bio {
            min-height: calc(100vh - $stripe-height);
            
            .avatar {
                width: 180px;
                height: 180px;
                border-radius: 50%;
                margin-bottom: 1rem;
                
                img {
                    object-fit: cover;
                    object-position: center center;
                }
            }
            
            .resume-header {
                margin-bottom: 1rem;
                
                p {
                    font-size: 1.2rem;
                    margin: 0;
                    color: $light-color;
                }
            }
            
            .social-links {
                margin-bottom: 1rem;
                
                a {
                    margin-right: 1rem;
                }
            }
            
            .contact-info {
                margin-bottom: 1rem;
                
                a {
                    display: block;
                }
                
                p {
                    font-size: 1.2rem;
                    margin: 0;
                    font-weight: 500;
                }
            }
            
            a {
                display: inline-block;
                color: $light-color;
                text-decoration: none;
                
                i {
                    margin-right: 0.5rem;
                }
                
                &:hover {
                    color: $light-color;
                }
            }
        }
        
        &__highlights {
            .resume-section {
                h3 {
                    font-size: 1.5rem;
                    padding-bottom: 2rem;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    margin-bottom: 1rem;
                }
                
                p {
                    font-size: 1.2rem;
                    margin: 0;
                }
            }
        }
        
        &__contact {
            padding-top: $container-padding-y;
            padding-bottom: 16rem;
            padding-left: $container-padding-x;
            padding-right: $container-padding-x;
        }
        
        &__apps {
            margin-top: 2rem;
            


            .apps-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                gap: 2rem;
                margin-top: 1.5rem;
                width: 100%;
              }
              
              .app-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1rem;
                transition: transform 0.2s ease-in-out;
                position: relative;
              }
              
              .app-item:hover {
                transform: translateY(-5px);
              }
              
              .app-icon {
                width: 96px;
                height: 96px;
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: 0.5rem;
              }
              
              .app-icon img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
              
              .app-item h3 {
                font-size: 1.1rem;
                margin: 0;
                text-align: center;
              }
              
              @media (max-width: 768px) {
                .apps-grid {
                  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                }
              }
              
              @media (max-width: 480px) {
                .apps-grid {
                  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
                }
              }
        }
        
        .grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 4rem;
            
            .grid-col {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 640px;
                
                // Add styles to center-align the contents of the first column
                &:first-child {
                    text-align: center;
                    
                    .avatar {
                        margin-bottom: 1rem;
                    }
                    
                    .resume-header {
                        margin-bottom: 1rem;
                        
                        p {
                            font-size: 1.2rem;
                            margin: 0;
                            color: $light-color;
                        }
                    }
                }
            }
        }
    }
    
    .resume-skills {
        text-align: center;
        width: 100%;
        left: 0;
        right: 0;
        
        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        
        .animated-stripes-container {
            display: flex;
            width: 100%;
            overflow: hidden;
            position: relative; /* Add relative positioning */
        }
        
        .animated-stripe {
            height: $stripe-height;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $tertiary-color;
            background: linear-gradient(90deg, $primary-color, $secondary-color);
            background-size: 200% 100%;
            animation: changeColor 2s alternate infinite;
            color: $tertiary-color;
            
            .stripe-text {
                white-space: nowrap;
                animation: moveText 20s linear infinite;
                
                &--item {
                    margin-right: 1.5em;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }
        }
        
        @keyframes moveText {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-100%);
            }
        }
    }
}