@import '../styles/theme.scss';

.back-button {
    top: 8px;
    left: 8px;
    position: fixed;
    width: 48px;
    height: 48px;
    background: linear-gradient(to bottom right, $primary-color, $tertiary-color, $tertiary-color, $secondary-color, $primary-color);
    background-size: 300% 300%;
    background-position: 0% 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 1000000000;
    
    svg {
        width: 24px;
        height: 24px;
        fill: $dark-color;
    }

    &:hover {
        background-position: 50% 50%;

        svg {
            width: 24px;
            height: 24px;
            fill: $dark-color;
        }
    }
    
    &:focus {
        outline: none;
        background-position: 100% 100%;
        
        svg {
            width: 24px;
            height: 24px;
            fill: $light-color;
        }
    }
}
