@import '../styles/theme.scss';

.button {
  display: inline-block;
  padding: 16px 24px;
  margin-top: 32px;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.3s, background-color 0.3s, border 0.3s;
  background-blend-mode: overlay;
  position: relative;

  a {
    color: inherit;
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: 600;
  }

  &.primary,
  &.secondary,
  &.dark,
  &.light.invert,
  &.light {
    /* Common styling */
    color: #fff;
    border: 2px solid #fff;
    mix-blend-mode: overlay;
    transition: all 0.3s;

    &:hover {
      color: $dark-color;
      border: 2px solid transparent;
      background-color: #fff;
    }
  }

  &.primary {
    background-color: $primary-color;
  }

  &.secondary {
    background-color: $secondary-color;
  }

  &.dark,
  &.light.invert {
    background-color: transparent;
  }

  &.light {
    background-color: $light-color;
  }
}
