@import '../styles/theme.scss';

.recent-posts-container {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  text-align: center;

  ul {
    padding-left: 0;
  }

  .posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;

    .post-card {
      text-align: left;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: $border-radius;
      padding: 20px;
      grid-column: span 1;
          
      a {
        text-decoration: none;
      }

      &__title {
        background: linear-gradient(to right, $primary-color, $secondary-color);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: 8px;
      }

      &__date {
        color: $medium-color;
      }

      &__excerpt {
        color: $dark-color;
      }

      &__thumbnail {
        width: 100%;
        padding-top: 50%;
        border: 1px solid $light-color;
        border-radius: $border-radius;
        position: relative;

        img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          object-position: center center;
        }
      }

      h3 {
        margin-top: 10px;
        font-size: 1.2rem;
      }
    }
  }
}