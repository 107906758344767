@import '~locomotive-scroll/dist/locomotive-scroll.css';

// Colors
$primary-color: #45289a;
$secondary-color: #1C6575;
$tertiary-color: #C2FFC6;
$dark-color: #111122;
$medium-color:   #435160;
$light-color:   #dfe8f0;

// Spacing
$border-radius: 6px;
$container-padding-y: 8rem;
$container-padding-x: min(72px, 8vw);

body {
  background: $dark-color;
}

html {
  font-size: 16px;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.65rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.25rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    font-size: 1.35rem;
  }
  
  h4 {
    font-size: 1,2375rem;
  }
  
}
a {
  &,
  &:visited {
    color: $tertiary-color;
  }
}

// Locomotive Scroll
[data-scroll-container] {
  position: relative;
  will-change: transform;
}

[data-scroll-section] {
  position: relative;
}

// WebKit Scrollbar
$scrollbar-width: 2px;

::-webkit-scrollbar {
  width: $scrollbar-width;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $medium-color;
  border-radius: $border-radius;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Firefox Scrollbar */
.scrollbar {
  width: $scrollbar-width;
  background-color: transparent;
}

.scrollbar-thumb {
  background-color: $medium-color;
  border-radius: $border-radius;
}

.scrollbar-track {
  background-color: transparent;
}

/* Change the color of selected text */
::selection {
  background-color: $tertiary-color;
  color: $dark-color;
}
